import { Component, OnInit, DoCheck, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { GLOBAL } from '@app/global';

@Component ({
  selector: 'app-user-circle',
  templateUrl: './user_circle.component.html',
  styleUrls: ['./user_circle.component.scss']
})

export class UserCircleComponent implements AfterViewInit {

  @Input() imagePath = GLOBAL.profileUploads;
  @Input() userImage = '';
  @Input() size = '30px';
  @Input() border = '2px';

  @ViewChild('profileImage', { static: false }) profileImage: ElementRef;
  @ViewChild('profileImageDefault', { static: false }) profileImageDefault: ElementRef;
  @ViewChild('profileImageContainer', { static: true }) profileImageContainer: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.updateImage();
    if (this.profileImageDefault) {
      this.profileImageDefault.nativeElement.style.backgroundImage = 'url(' + this.userImage + ')';
      this.profileImageDefault.nativeElement.style.backgroundPosition = 'center';
      this.profileImageDefault.nativeElement.style.backgroundSize = 'cover';
      this.profileImageDefault.nativeElement.style.width = this.size;
      this.profileImageDefault.nativeElement.style.height = this.size;
    }
    this.profileImageContainer.nativeElement.style.width = this.size;
    this.profileImageContainer.nativeElement.style.height = this.size;
  }

  updateImage() {
    if (this.userImage && this.profileImage) {
      this.profileImage.nativeElement.style.backgroundImage = 'url(' + this.imagePath + this.userImage + ')';
      this.profileImage.nativeElement.style.backgroundPosition = 'center';
      this.profileImage.nativeElement.style.backgroundSize = 'cover';
      this.profileImage.nativeElement.style.width = this.size;
      this.profileImage.nativeElement.style.height = this.size;
      this.profileImage.nativeElement.style.borderWidth = this.border;
      this.profileImage.nativeElement.style.left = -this.border;
      this.profileImage.nativeElement.style.top = -this.border;
    }
  }
}
