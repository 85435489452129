import { Component, OnInit } from '@angular/core';

import { BasicConfigService } from '@app/services/basic_config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
      BasicConfigService
    ]
})

export class FooterComponent implements OnInit {

  cookiesDisclaimer = false;
  termsAndConditionsDisclaimer = false;
  legalAdviceDisclaimer = false;
  privacyPolicy = false;

  constructor(private basicConfigService: BasicConfigService) {
  }

  ngOnInit() {
    this.configureLinks();

    BasicConfigService.getEmitter()
    .subscribe(item => {
      this.configureLinks();
    });
  }

  configureLinks() {
    this.cookiesDisclaimer = this.basicConfigService.getProperty('cookiesDisclaimer') === '1';
    this.termsAndConditionsDisclaimer = this.basicConfigService.getProperty('termsAndConditionsDisclaimer') === '1';
    this.legalAdviceDisclaimer = this.basicConfigService.getProperty('legalAdviceDisclaimer') === '1';
    this.privacyPolicy = this.basicConfigService.getProperty('privacyPolicy') === '1';
  }
}
