import { Component, OnInit, DoCheck, Input, forwardRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { MDBDatePickerComponent, ClockPickerComponent } from 'ng-uikit-pro-standard';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component ({
  selector: 'date-time-picker',
  templateUrl: './date_time_picker.component.html',
  styleUrls: ['./date_time_picker.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimePickerComponent),
    multi: true
  } ]
})

export class DateTimePickerComponent implements DoCheck, OnInit, ControlValueAccessor {

  @ViewChild('datePicker', { static: true }) datePicker: MDBDatePickerComponent;
  @ViewChild('timePicker', { static: true }) timePicker: ClockPickerComponent;

  @Input() public group: FormGroup;
  @Input() public label: string;

  private dateFormat = 'dd/MM/yyy';
  private timeFormat = 'HH:mm';

  private _value = '';

  public dateTimePickerFormGroup: FormGroup;

  static required(input: FormControl) {
    const pattern = /^\d{4}-\d{2}-\d{2}(T)\d{2}(:)\d{2}$/;
    return pattern.test(input.value) ? null : {
      dateRequired: {
        valid: false
      }
    };
  }

  propagateChange: any = () => {};

  constructor(public translate: TranslateService, public datepipe: DatePipe,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.dateTimePickerFormGroup = this.formBuilder.group({
      'date':         ['', [Validators.required]],
      'time':         ['', [Validators.required]],
    });
    this.datePicker.addLocale({
      es: {
        dayLabels: {su: 'Do', mo: 'Lu', tu: 'Ma', we: 'Mi', th: 'Ju', fr: 'Vi', sa: 'Sa'},
        dayLabelsFull: { su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miércoles', th: 'Jueves', fr: 'Viernes', sa: 'Sábado' },
        monthLabels: {1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun',
                      7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'},
        monthLabelsFull: { 1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio',
                           7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre' },
        dateFormat: 'dd/mm/yyyy',
        todayBtnTxt: 'Hoy',
        clearBtnTxt: 'Limpiar',
        closeBtnTxt: 'Cerrar',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
      }
    });
    this.translate.get('general.ok', null).subscribe((res: string) => {
      this.timePicker.buttonLabel = res;
    });
  }

  ngDoCheck() {
    const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    if (this.dateTimePickerFormGroup && this.dateTimePickerFormGroup.controls.date) {
      const stdDate = this.dateTimePickerFormGroup.controls.date.value.replace(pattern, '$3-$2-$1');
      const newDate = stdDate + 'T' + this.dateTimePickerFormGroup.controls.time.value;
      if (newDate !== this._value) {
        this._value = newDate;

        this.onChange(null);
      }
    }
  }

  writeValue(value: any) {
    if (value) {
     this._value = value;
     this.dateTimePickerFormGroup.controls.date.setValue(this.datepipe.transform(value, this.dateFormat));
     this.dateTimePickerFormGroup.controls.time.setValue(this.datepipe.transform(value, this.timeFormat));
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void { }

  onChange(event) {
    this.propagateChange(this._value);
  }
}
