import { Component, OnInit} from '@angular/core';

import { BaseComponent } from '@app/components/baseComponent';
import { Location } from '@angular/common';
import { UrlService } from '@app/services/url.service';

@Component({
    selector: 'app-url',
    templateUrl: './url.component.html',
    providers: [
      UrlService
    ]
})

export class UrlComponent extends BaseComponent implements OnInit {

  public image = '';

  constructor(public qrService: UrlService,
    private _location: Location) {
    super(null);
  }

  ngOnInit() {
    this.qrService.autogenerate().subscribe(
      response => {
        if (response.status === 0) {
          this.image = response.data;
        }
      },
      error => {this.handleError(error, 'QR'); });
  }

  goBack() {
    this._location.back();
  }
}
