import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { BaseComponent } from '@app/components/baseComponent';

import { ModalDirective } from 'ng-uikit-pro-standard';

@Component ({
    selector: 'app-side-modal',
    templateUrl: './side_modal.component.html',
    styleUrls: ['./side_modal.component.scss']
})

export class SideModalComponent extends BaseComponent {

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() modalClosed = new EventEmitter();

  constructor() {
    super(null);
  }

  public show() {
    this.modal.show();
  }

  public onClose(event) {
    if (this.modalClosed) {
      this.modalClosed.emit(event);
    }
  }
}
