import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

// i18n
import { TranslateModule } from '@ngx-translate/core';

// For MDB Angular Pro
import { DatepickerModule, TimePickerModule, WavesModule, MDBBootstrapModule, PopoverModule } from 'ng-uikit-pro-standard';
// text editor
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
// My Modules
import { DateTimePickerComponent } from '@app/modules/_shared/components/date_time_picker/date_time_picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '@app/modules/_shared/pipes/safe_html.pipe';
import { SafeUrlPipe } from '@app/modules/_shared/pipes/safe_url.pipe';
import { InsightCardComponent } from './components/insight_card/insight_card.component';
import { UserCircleComponent } from './components/user_circle/user_circle.component';
import { AutoGrowTextAreaComponent } from './components/autogrow_text_area/autogrow_text_area.component';
import { SideModalComponent } from './components/side_modal/side_modal.component';
import { FooterColumnGroup } from 'primeng/components/common/shared';
import { FooterComponent } from './components/footer_component/footer.component';
import { DragDropDirective } from './directives/drag_drop_directive.directive';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        DatepickerModule,
        TimePickerModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WavesModule,
        MDBBootstrapModule
    ],
    exports: [
        TranslateModule,
        DateTimePickerComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        NgxTrumbowygModule,
        InsightCardComponent,
        UserCircleComponent,
        AutoGrowTextAreaComponent,
        FooterComponent,
        SideModalComponent,
        DragDropDirective
    ],
    declarations: [
        DateTimePickerComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        InsightCardComponent,
        UserCircleComponent,
        AutoGrowTextAreaComponent,
        SideModalComponent,
        FooterComponent,
        DragDropDirective
    ],
    providers: [ DatePipe ],
    schemas: [ NO_ERRORS_SCHEMA ]
})
export class SharedModule { }
