import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GLOBAL } from '@app/global';

interface Response {
  status: number;
  data: any;
}

@Injectable()
export class LegalService {
  public url: string;

  constructor(private _http: HttpClient) {
     this.url = GLOBAL.url;
  }

  get(key: string) {
    return this._http.get<Response>(this.url + 'legal/' + key);
  }
}
