import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// JWT
import { JwtModule } from '@auth0/angular-jwt';
// MDB
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, PopoverModule } from 'ng-uikit-pro-standard';
// Material Design
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
// i18n
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// progress bar
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// wyswyg
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
// confirmation
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { AppRoutingModule } from '@app/app.routing';

import { AppComponent } from '@app/app.component';
import { HttpModule } from '@angular/http';

// Component import
//// Navigation
import { NavbarComponent } from '@app/components/navbarComponent/navbar.component';
//// Auth
import { AuthService } from '@app/services/auth.service';
//// Service
//// Interceptor
import { JwtInterceptor } from '@app/interceptors/jwt.interceptor';
import { SharedModule } from './modules/_shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LegalComponent } from './components/legal_component/legal.component';
import { UrlComponent } from './components/url_component/utl.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Modules

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('jwtToken');
}

@NgModule({
  imports: [
    // generic routes
    AppRoutingModule,
    // i18n
    SharedModule,
    // Material
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    // global modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ['localhost:3001'],
        // blacklistedRoutes: ['localhost:3001/auth/']
      }
    }),
    LoadingBarHttpClientModule,
    ToastModule.forRoot(),
    HttpModule,
    MDBBootstrapModulesPro.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'success',
      cancelButtonType: 'danger'
    }),
    NgxTrumbowygModule.withConfig({
      lang: 'es',
      svgPath: './assets/icons.svg',
      removeformatPasted: true,
      autogrow: true,
      imageWidthModalEdit: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    NgbModule // to be exported to a shared module
  ],
  declarations: [
    AppComponent,
    // Navigation
    NavbarComponent,
    LegalComponent,
    UrlComponent
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // MDB
    MDBSpinningPreloader,
  ],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
