import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';
import { url } from 'inspector';

@Injectable()
export class RoutingState {
  private history = [];
  private isBack = false;

  constructor(
    private router: Router,
    private location: PlatformLocation
  ) {
    location.onPopState(() => {
        this.history.pop();
        this.isBack = true;
    });
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(({url}: NavigationStart) => {
          if (!this.isBack) {
            this.history.push(url);
          } else {
            this.isBack = false;
          }
      });
  }

  public hasHistory(): boolean {
    return this.history.length > 1;
  }

  public pop(): string {
      return this.history.pop();
  }
}
