import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { Subject, throwError } from 'rxjs/index';
import 'rxjs/add/observable/empty';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private auth: AuthService;
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private inj: Injector, private router: Router) { }

  private applyCredentials(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.get()}`,
        Accept: 'application/json'
      }
    });
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
        return new Observable(observer => {
            this.tokenRefreshed$.subscribe(() => {
                observer.next();
                observer.complete();
            });
        });
    } else {
        this.refreshTokenInProgress = true;

        return this.auth.refreshToken()
           .do(() => {
                this.refreshTokenInProgress = false;
                this.tokenRefreshedSource.next();
            });
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.auth) {
      this.auth = this.inj.get(AuthService);
    }

    request = this.applyCredentials(request);

    return next.handle(request).pipe(catchError((error, caught) => {
      // intercept the respons error and displace it to the console

      let redirect = false;
      if (error.status === 401) {
        if (error.url.indexOf('auth/refresh') !== -1) {
          redirect = true;
          this.auth.softLogout();
          if (this.router.url.indexOf('/user/login') === -1 && this.router.url.indexOf('/user/signup') === -1) {
            const path = document.location.pathname;
            this.router.navigate(['/user/login'], {
              queryParams: {
                  redirectTo: path
              }
            });
          }

          return throwError(error);
        } else {
          return this.refreshToken().pipe(
                    switchMap((response: any) => {
                        if (response.data && response.data.token) {
                          this.auth.set(response.data.token);
                        }
                        request = this.applyCredentials(request);
                        return next.handle(request);
                    }));
        }
      }

      return throwError(error);
    }) as any);
  }
}
