import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';
import {Observable} from "rxjs/Rx";

@Injectable()
export class WebSocketService {
  private url: string;
  constructor() {}

  private subject: Rx.Subject<MessageEvent>;
  private ws: WebSocket

  public connect(url): Rx.Subject<MessageEvent> {
    this.url = url;
    this.subject = this.create(url);
    console.log('Successfully connected: ' + url);
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    const ws = new WebSocket(url);
    this.ws = ws;

    const observable = new Observable((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    const observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        } else if (ws.readyState === WebSocket.CONNECTING) {
          (async () => {
            // let's wait until it connects
            while (ws.readyState === WebSocket.CONNECTING) {
              await this.delay(100);
            }
            ws.send(JSON.stringify(data));
          })();
        }
        // On closed or closing nothing should be sent
      }
    };
    return Rx.Subject.create(observer, observable);
  }

  public getReadyState(){
    return this.ws.readyState
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
