import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Rx';
import {WebSocketService} from '@app/services/web_socket.service';
import {ISubscriptor} from "@app/subsciptor.interface";

const CHAT_URL = 'ws://127.0.0.1:90';

@Injectable({
  providedIn: 'root',
})
export class ApolloService {
  public messages: Subject<any>;
  private wsService: WebSocketService;
  private subscriptions: Set<any>;

  constructor(wsService: WebSocketService) {
    this.wsService = wsService;
    //this.connect();
  }

  public reconnect() {
    setTimeout(function() {
      this.messages = null;
      this.connect();
    }.bind(this), 5000);
  }

  public connect() {
    this.subscriptions = new Set();
    this.createSubject();
    this.basicSubscription();
    this.login();
  }

  private createSubject() {
    this.messages = <Subject<any>>this.wsService.connect(CHAT_URL).map(
      (response: any): any => {
        return JSON.parse(response.data);
      }
    );
  }

  private basicSubscription() {
    this.messages.subscribe(
      msg => {
        this.notify(msg);
      },
      error => {
        console.error(error);
        if (error.target.readyState === WebSocket.CLOSED ||
          error.target.readyState === WebSocket.CLOSING) {
          this.reconnect();
        }
      },
      () => {
        console.error('closed socket');
        this.reconnect();
      });
  }

  private login() {
    const token = localStorage.getItem('jwtToken');
    this.messages.next({
      message: token,
      type: 'who'
    });
    console.log('reconnected');
  }

  public notify(msg: any) {
    console.log("notify", msg);
    console.log(this.subscriptions)
    if (!this.subscriptions) {
      return;
    }
    for (const subscription of this.subscriptions) {
      subscription.event(msg);
    }
  }

  public subscribeObs(observer: ISubscriptor) {
    if (!this.subscriptions) {
      this.subscriptions = new Set();
    }
    this.subscriptions.add(observer);
  }

  unsuscribe(observer: ISubscriptor) {
    if (!this.subscriptions) {
      this.subscriptions = new Set();
    }
    this.subscriptions.delete(observer);
  }

  send(param: object) {
    (async () => {
      //await this.delay(100);
      this.messages.next(param);
    })();
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
