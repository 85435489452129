import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GLOBAL } from '@app/global';

interface Response {
  status: number;
  data: any;
}

@Injectable()
export class BasicConfigService {
  private static emitter: EventEmitter<void> = new EventEmitter();
  public url: string;


  static getEmitter() {
    return BasicConfigService.emitter;
  }

  constructor(private _http: HttpClient) {
     this.url = GLOBAL.url;
  }

  list(callback: Function) {
    this._http.get<Response>(this.url + 'config').subscribe(
      response => {
        if (response.status === 0) {
          localStorage.setItem('config', JSON.stringify(response.data));
          BasicConfigService.emitter.emit(response.data);
          if (callback) {
            if (callback) {
              callback(response.data);
            }
          }
        }
      },
      error => { console.error('error loading config'); }
    );
  }

  getProperty(key: string) {
    const data: any = JSON.parse(localStorage.getItem('config'));
    if (data) {
      const property = data.filter(s => s.key === key);
      if (property.length > 0) {
        return property[0].value;
      }
    }
    return null;
  }
}
