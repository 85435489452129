import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LegalComponent } from './components/legal_component/legal.component';
import { UrlComponent } from './components/url_component/utl.component';


const routes: Routes = [
  { path: '',                       redirectTo: '/event', pathMatch: 'full' }, // ruta vacía
  { path: '', data: { breadcrumb: 'Home'},
    children: [
      { path: 'legal/:contract',        component: LegalComponent},
      { path: 'qr',                     component: UrlComponent},
      { path: 'user',                   loadChildren: () => import(`./modules/user/user.module`).then(m => m.AuthModule) },
      { path: 'event',                  loadChildren: () => import(`./modules/event/event.module`).then(m => m.EventModule)},
      { path: 'admin',                  loadChildren: () => import(`./modules/admin/admin.module`).then(m => m.AdminModule)},
      { path: 'chat',                   loadChildren: () => import(`./modules/chat/chat.module`).then(m => m.ChatModule)},
  ]},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
