import { Component, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component ({
  selector: 'app-autogrow-textarea',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AutoGrowTextAreaComponent),
        multi: true
    }
  ],
  template: `<div #container contentEditable="true" (keyup)="divChanged(container.innerText)"></div>`,
  styles: [`div {
    min-height: 3rem;
    max-height: 10rem;
    overflow: scroll;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
}`],
})

export class AutoGrowTextAreaComponent implements ControlValueAccessor {
  @ViewChild('container', { static: true }) container;

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.container.nativeElement.innerText = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  propagateChange = (_: any) => {};

  registerOnTouched(fn: any): void {}

  divChanged(str) {
    this.propagateChange(str);
  }
}
