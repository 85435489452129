import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { User } from '@app/models/user.model';

import { GLOBAL } from '@app/global';
import { UserCircleComponent } from '@app/modules/_shared/components/user_circle/user_circle.component';
import { BaseComponent } from '../baseComponent';
import { ToastService } from 'ng-uikit-pro-standard';
import { LegalService } from '@app/services/legal.service';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.scss'],
    providers: [
      LegalService
    ]
})

export class LegalComponent extends BaseComponent implements OnInit {
  public userIdentity: User;
  public global;
  public impersonated = false;
  public contract = '';
  public text = '';
  @ViewChild('userCircle', { static: false }) userCircle: UserCircleComponent;

  constructor(private legalService: LegalService,
    private route: ActivatedRoute,
    public toastr: ToastService) {
    super(toastr);
    this.global = GLOBAL;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['contract']) {
        this.contract = params['contract'];
        this.legalService.get(this.contract).subscribe(response => {
          if (response.status === 0 && response.data && response.data.text) {
            this.text = response.data.text;
          }
        });
      }
    });
  }
}
