import { ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, FormControl } from '@angular/forms';

export class BaseComponent {
  constructor(public toastr: ToastService) {
  }

  public isAllowed(actionArray: String[], acronym?: string) {
    let actions = JSON.parse(localStorage.getItem('jwtUserActions'));
    if (!actions) {
      return false;
    }
    let userEventActions = null;
    if (acronym) {
      userEventActions = JSON.parse(localStorage.getItem('jwtUserEventActions'));
      if (Array.isArray(userEventActions)) {
        userEventActions = userEventActions.filter(x => x.acronym === acronym);
        if (Array.isArray(userEventActions) && userEventActions.length > 0) {
          actions = actions.concat(userEventActions[0].actions);
        }
      }
    }

    return actionArray.some(function(action) {
      if (action === 'exit_event') { // admins ('wildcard') cannot exit events
        return  actions.filter(x => x === action).length > 0 && userEventActions &&
            userEventActions[0].actions.filter(x => x === 'wildcard').length === 0;
      } else {
        return actions.filter(x => x === 'wildcard').length > 0 || actions.filter(x => x === action).length > 0;
      }
    });
  }

  protected handleError(error, element: string) {
    if (error && error.status) {
      let errorMessage = '';
      switch (error.status) {
        case 404:
          errorMessage = 'Elemento no encontrado';
          break;
        case 500:
          errorMessage = 'Error inesperado';
          break;
      }
      if (error.status !== 401) {
        this.toastr.error(errorMessage, element);
      }
    } else {
      this.toastr.error(element, 'Error');
    }
    console.log(<any>error);
  }

  protected validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched();
        } else if (control instanceof FormGroup) {
            this.validateAllFields(control);
        }
    });
  }
}
