import { Component, OnInit, DoCheck, Input, forwardRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { MDBDatePickerComponent, ClockPickerComponent } from 'ng-uikit-pro-standard';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component ({
  selector: 'insight-card',
  templateUrl: './insight_card.component.html',
  styleUrls: ['./insight_card.component.scss']
})

export class InsightCardComponent implements DoCheck, OnInit {

  @Input() title = '';
  @Input() value = '';
  @Input() icon = '';
  @Input() bg = '';

  constructor() {}

  ngOnInit() {
  }

  ngDoCheck() {
  }
}
